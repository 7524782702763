import { BaseRouter } from './router';
import { ApolloProvider } from '@apollo/client';
import { setupGraphQlClient } from 'graphql/client';
import { Provider } from 'react-redux';
import store, { persistor } from 'store';
import { PersistGate } from 'redux-persist/integration/react';
import './reset.css';
import { CartProvider } from 'context/cartContext';
import { BrowserRouter as Router } from 'react-router-dom';
import { App as AppProvider } from 'antd';
import { SocketProvider } from 'context/noticationContext';
import { ThemeProvider } from 'context/themeContext';
import { AlertProvider } from 'context/alertContext';
import './themes/style.css';
import { useEffect } from 'react';

function App() {
    const client = setupGraphQlClient();
    useEffect(() => {
        // Bắt lỗi JavaScript
        window.onerror = () => {
            SendErrorToReactNative();
        };

        // Bắt lỗi Promise bị reject không xử lý
        window.addEventListener('unhandledrejection', (event) => {
            console.error('Unhandled promise rejection caught:', {
                reason: event.reason,
            });
            SendErrorToReactNative();
            // Gửi log đến server
        });

        // Cleanup sự kiện (nếu cần)
        return () => {
            window.onerror = null;
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            window.removeEventListener('unhandledrejection', () => {});
        };
    }, []);
    const SendErrorToReactNative = () => {
        if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(
                JSON.stringify({ type: 'error', message: 'reset cache' }),
            );
        }
    };
    // Một số ví dụ lỗi để kiểm tra

    return (
        <PersistGate loading={null} persistor={persistor}>
            <Provider store={store}>
                <AppProvider>
                    <ApolloProvider client={client}>
                        <Router>
                            <ThemeProvider>
                                <CartProvider>
                                    <SocketProvider>
                                        <AlertProvider>
                                            <BaseRouter />
                                        </AlertProvider>
                                    </SocketProvider>
                                </CartProvider>
                            </ThemeProvider>
                        </Router>
                    </ApolloProvider>
                </AppProvider>
            </Provider>
        </PersistGate>
    );
}

export default App;
